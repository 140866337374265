<template lang="pug">
  .user-manage
    .container
      .row.justify-content-md-center
        .col-lg-6
          h1 お問い合わせ
          p 以下のフォームにご入力いただき「入力内容の確認」ボタンをクリックしてください。
          p （バグ報告や岩場情報ご提供は
            router-link(to="/feed_back/input") こちら
            |よりお願い致します。）
          .alert.alert-danger(v-if="messages.length > 0") 
            li(v-for="message in messages") {{ message }}
          form.needs-validation(@submit.prevent="exec" novalidate)
            .form-group
              label(for="name") お名前
              input#name.form-control(type="text" placeholder="お名前" v-model="name" required)
              .invalid-feedback お名前を入力してください
            .form-group
              label(for="mail") メールアドレス
              input#mail.form-control(type="email" placeholder="メールアドレス" v-model="mail" required)
              .invalid-feedback メールアドレスを正しく入力してください
            .form-group
              label(for="Inquiry") 本文
              textarea#Inquiry.form-control(type="Inquiry" rows="5" v-model="Inquiry" required)
              .invalid-feedback お問い合わせ内容を入力してください
              p.mt-3 
                router-link(to="/info/personal-privacy" target="_blank" rel="noopener") 個人情報の取り扱いについて
                |をお読み頂き、同意の上送信して下さい。
            input.btn.btn-primary(type="submit" value="同意して次へ" v-bind:disabled="isDisabled")
</template>

<script>
export default {
  data() {
    return {
      name: this.$route.params.name,
      mail: this.$route.params.mail,
      Inquiry: this.$route.params.Inquiry,
      messages: [],
      isDisabled: false,
    }
  },
  methods: {
    toHalfWidth(value) {
      return value.replace(/[Ａ-Ｚａ-ｚ０-９．＠＋－]/g, s => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      })
    },
    exec(e) {
      this.isDisabled = true

      //表示中のメッセージをクリア
      this.messages = []

      this.name = this.name == undefined ? '' : this.name.trim()
      this.mail = this.mail == undefined ? '' : this.mail.trim()
      this.Inquiry = this.Inquiry == undefined ? '' : this.Inquiry.trim()

      this.mail = this.toHalfWidth(this.mail)

      //入力チェック
      if(this.name.length == 0){
        this.messages.push('お名前を入力してください。')
      }
      if(this.mail.length == 0){
        this.messages.push('メールアドレスを入力してください。')
      }
      if(this.Inquiry.length == 0){
        this.messages.push('本文を入力してください。')
      }

      if(this.messages.length > 0) {
        this.isDisabled = false
        return
      }

      //エラー処理
      if (e.target.checkValidity() === false) {
        e.target.classList.add('was-validated')
        e.stopPropagation();
        this.isDisabled = false
        return
      }

      this.$router.push({ name: 'inquiry_form_confirm', params: {mail: this.mail, name: this.name, Inquiry: this.Inquiry}}) //ページ移動
    }
  }
}
</script>