import { render, staticRenderFns } from "./input.vue?vue&type=template&id=5df69051&lang=pug&"
import script from "./input.vue?vue&type=script&lang=js&"
export * from "./input.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\git\\front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5df69051')) {
      api.createRecord('5df69051', component.options)
    } else {
      api.reload('5df69051', component.options)
    }
    module.hot.accept("./input.vue?vue&type=template&id=5df69051&lang=pug&", function () {
      api.rerender('5df69051', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/inquiry_form/input.vue"
export default component.exports