var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-manage" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-lg-6" }, [
          _c("h1", [_vm._v("お問い合わせ")]),
          _c("p", [
            _vm._v(
              "以下のフォームにご入力いただき「入力内容の確認」ボタンをクリックしてください。"
            )
          ]),
          _c(
            "p",
            [
              _vm._v("（バグ報告や岩場情報ご提供は"),
              _c("router-link", { attrs: { to: "/feed_back/input" } }, [
                _vm._v("こちら")
              ]),
              _vm._v("よりお願い致します。）")
            ],
            1
          ),
          _vm.messages.length > 0
            ? _c(
                "div",
                { staticClass: "alert alert-danger" },
                _vm._l(_vm.messages, function(message) {
                  return _c("li", [_vm._v(_vm._s(message))])
                }),
                0
              )
            : _vm._e(),
          _c(
            "form",
            {
              staticClass: "needs-validation",
              attrs: { novalidate: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.exec($event)
                }
              }
            },
            [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "name" } }, [_vm._v("お名前")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.name,
                      expression: "name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "name",
                    type: "text",
                    placeholder: "お名前",
                    required: ""
                  },
                  domProps: { value: _vm.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.name = $event.target.value
                    }
                  }
                }),
                _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v("お名前を入力してください")
                ])
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "mail" } }, [
                  _vm._v("メールアドレス")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mail,
                      expression: "mail"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "mail",
                    type: "email",
                    placeholder: "メールアドレス",
                    required: ""
                  },
                  domProps: { value: _vm.mail },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.mail = $event.target.value
                    }
                  }
                }),
                _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v("メールアドレスを正しく入力してください")
                ])
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "Inquiry" } }, [_vm._v("本文")]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.Inquiry,
                      expression: "Inquiry"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "Inquiry",
                    type: "Inquiry",
                    rows: "5",
                    required: ""
                  },
                  domProps: { value: _vm.Inquiry },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.Inquiry = $event.target.value
                    }
                  }
                }),
                _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v("お問い合わせ内容を入力してください")
                ]),
                _c(
                  "p",
                  { staticClass: "mt-3" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: "/info/personal-privacy",
                          target: "_blank",
                          rel: "noopener"
                        }
                      },
                      [_vm._v("個人情報の取り扱いについて")]
                    ),
                    _vm._v("をお読み頂き、同意の上送信して下さい。")
                  ],
                  1
                )
              ]),
              _c("input", {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "submit",
                  value: "同意して次へ",
                  disabled: _vm.isDisabled
                }
              })
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }